import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/api';
import { mostrarMensajeAlerta } from '../util/alet';

const AuthContext = createContext();

const getUserFromStorage = () => {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null;
};

const setUserToStorage = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
};



const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(getUserFromStorage());
    const [error, setError] = useState('');
    const navigator = useNavigate();


    useEffect(() => {
        const fetchToken = async () => {
            if (user) {
                try {
                    const response = await api.post('/user/token', { token: localStorage.getItem('refreshToken') });
                    localStorage.setItem('user-token', response.data.token);
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        fetchToken(); 

    }, [user]);

    const getUser = useCallback(async () => {
        await api.get('/user/' + user.id).then(res => {
            if (res.status === 200) {
                return res.data.user;
            }
        }).catch(error => {
            console.error(error);
        })
    }, [user]);

    const logout = useCallback(async () => {
        try {
            await api.post('/user/logout', { refreshToken: localStorage.getItem('refreshToken') });
        } catch (error) {
            console.error(error);
        }
        setUser(null);
        localStorage.removeItem("user");
        localStorage.removeItem("user-token");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("lastActiveTime");
        localStorage.removeItem("lastExternalReferrerTime");
        localStorage.removeItem("lastExternalReferrer");
        if ('caches' in window) {
            caches.keys().then((cacheNames) => {
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
            });
        }
        sessionStorage.clear()
    }, []);


    useEffect(() => {
        const refreshToken = async () => {
            try {
                const response = await api.post('/user/token', {
                    token: localStorage.getItem('refreshToken'),
                });
                localStorage.removeItem("user-token");
                localStorage.removeItem("refreshToken");

                const { token, refreshToken } = response.data;

                localStorage.setItem('user-token', token);
                localStorage.setItem("refreshToken", refreshToken)

                const userNew = await getUser();

                setUser({
                    ...user,
                    permisos: user.permisos,
                    token: token,
                    refreshToken: refreshToken,
                });

                setUserToStorage({
                    ...user,
                    token: token,
                    permisos: userNew?.permisos,
                    refreshToken: refreshToken,
                });
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    logout();
                }
                console.error(error);
            }
        };

        if (user && user.refreshToken) {
            const refreshTokenInterval = setInterval(refreshToken, 3 * 60 * 1000); // Actualizar el token cada 15 minutos
            return () => {
                clearInterval(refreshTokenInterval);
            };
        }
    }, [user, getUser, logout]);


    useEffect(() => {
        setUserToStorage(user);
    }, [user]);

    const login = async (values) => {
        try {
            const response = await api.post('/user/login', values);
            if (response.status === 200) {
                setUser(response.data);
                setUserToStorage(response.data);
                localStorage.setItem('user-token', response.data.token);
                localStorage.setItem("refreshToken", response.data.refreshToken)
                mostrarMensajeAlerta('success', 'Bienvenido', '¡Bienvenido nuevamente!', false);

                navigator("/booking")
            } else {

            }
        } catch (error) {
            console.error("error", error)
            if (error.response.status === 400) {
                mostrarMensajeAlerta("error", "Error", error.response.data.status, true);
                setError(error.response.data.message);
            } else {
                mostrarMensajeAlerta("error", "Error", 'Credenciales Invalidas!!', true);
                setError('Ocurrió un error al iniciar sesión.');
            }
        }
    };


    return (
        <AuthContext.Provider
            value={{
                user,
                error,
                login,
                logout,
                getUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
